$(function () {
    var pagetop = $('[data-pagetop]');
    pagetop.hide();
    $(window).on('scroll',function (){
        if ($(this).scrollTop() > 500) {
            pagetop.fadeIn();
        } else {
            pagetop.fadeOut();
        }
    });
    pagetop.on('click',function () {
        $('html, body').animate({
            scrollTop: 0
        }, 500, 'swing');
        return false;
    });
});